import React, { useState } from 'react'
import Logo from '../images/njangee-logo-white.png'
import FacebookIcon from '../images/icon-socialsFooter-facebook-circle.svg'
import LinkedInIcon from '../images/icon-socialsFooter-linkedin-circle.svg'
import TwitterIcon from '../images/icon-socialsFooter-twitter-circle.svg'
import YoutubeIcon from '../images/icon-socialsFooter-youtube-circle.svg'
import axios from 'axios'


export const ComingSoon: React.FC = () => {
    const [email, setEmail] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const isValidEmail = (email: string): boolean => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
    console.warn(process.env.REACT_APP__API)
    const handlePostEmail = () => {
        if (email === '' || isValidEmail(email)) {
            axios.post(`${process.env.REACT_APP__API}/contacts`, { email: email, name: "Subscription" })
                .then(res => {
                    setEmail('')
                    setShowErrorMessage(false)
                    setShowSuccessMessage(true)
                }).catch((error: any) => {
                    setShowErrorMessage(true)
                    setShowSuccessMessage(false)
                    setErrorMessage('Network Error. Please try again.')
                })
        } else {
            setErrorMessage('Please enter a valid email.')
            setShowErrorMessage(true)
        }
    }

    return (
        <div className='coming-soon'>
            <div className="container top">
                <br /><br />
                <img src={Logo} alt="" />
                <br /><br />
                <div>
                    <p className='h3'>Coming Soon.</p>
                    <p className='h1'>Get Notified When <br />
                        We Launch</p>
                    <br /><br />
                </div>
                <div>
                    <p>All your njangee meetings online. Manage your savings groups easily, securely, and from anywhere. No more physical meetings—everything is handled within the app.</p>
                    <div className={`input-form ${showErrorMessage && 'input-error'}`} style={{display: 'flex', flexWrap: 'nowrap' }}>
                        <input type="email" placeholder='Your Email' value={email} onChange={(e) => { setEmail(e.target.value) }} style={{width: '100%'}} />
                        <button className='btn btn-primary' onClick={handlePostEmail} style={{whiteSpace: "nowrap"}}>Notify Me</button>
                    </div>
                    {showErrorMessage && <small className='text-danger'>{errorMessage}</small>}
                    {showSuccessMessage && <small className='text-success'>Thanks you for subscribing to Njangee.</small>}
                </div>



            </div>
            <footer className=''>
                <div className="container footer">
                    <div className='follow-us'>
                        <p>Follow Us:</p>
                        <div className="footer-icons">
                            <a href="https://www.facebook.com/profile.php?id=61556626736854">
                                <img src={FacebookIcon} alt="" />
                            </a>
                            <a href="https://www.linkedin.com/company/njangee">
                                <img src={LinkedInIcon} alt="" />
                            </a>
                            <a href="https://www.youtube.com/@njangee">
                                <img src={YoutubeIcon} alt="" />
                            </a>
                            <a href="">
                                <img src={TwitterIcon} alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="copyright">
                        <small>&copy; Copyright 2023 njangee | Bringing all njangi meetings online.</small>
                    </div>
                </div>
            </footer>
        </div>
    )
}
